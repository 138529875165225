import "./article.css";

import React from "react";
import Rec from "./../../assets/images/Rectangle.svg";
import axios from "axios";
import parse from "html-react-parser";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";

const ArticleDetails = (props) => {
  const [data, setData] = useState({});

  useEffect(() => {
    getData(props.match.params?.id);
  }, []);

  const getData = (id) => {
    axios.get(`https://backend.jurisera.com/api/posts/${id}/`).then((res) => {
      setData(res.data);
      console.log(res.data);
    });
  };
  return (
    <div className="container mb-5 mt-5" id="article">
      <div className="py-2">
        <img
          src={data?.picture}
          alt={data.title}
          className="w-100 mb-3"
          height={350}
          style={{
            objectFit: "contain",
          }}
        />
        <div className="subheader">{data.title}</div>
        <div className="date">{data?.modified?.slice(0, -8)}</div>
        <div className="content py-2">
          <div className="pb-3">
            <img
              src={Rec}
              alt="service provided"
              style={{
                width: 17,
                height: 17,
              }}
            />

            <div
              className="m-0 px-2 pt-1 col-11 paragraph mb-0"
              dangerouslySetInnerHTML={{ __html: data?.description }}
            ></div>
          </div>

          {/* <div className="pb-3">
            <img
              src={Rec}
              alt="service provided"
              style={{
                width: 17,
                height: 17,
              }}
            />

            <span className="m-0 px-2 pt-1 col-11 paragraph mb-0">
              we have In-depth
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default ArticleDetails;
