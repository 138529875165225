import "./careers.css";

import React, { useEffect } from "react";

import CareerCard from "../../components/career-card/CareerCard";
import Rec from "./../../assets/images/Rectangle.svg";

const Careers = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mb-5 mt-5" id="careers">
      <div className="text-center">
        <span className="title">Careers</span>
      </div>
      <div className="py-2">
        {/* <div className="subheader">Paragraph Name</div> */}
        <div className="content">
          <div className="pb-3 d-flex align-items-baseline">
            <img
              src={Rec}
              alt="service provided"
              style={{
                width: 17,
                height: 17,
              }}
            />

            <span className="m-0 px-2 pt-1 col-11 paragraph mb-0">
              Thank you for your interest in joining Jurisera. Please submit
              your C.V. and cover letter to{" "}
              <a href="mailto:info@jurisera.com">info@jurisera.com</a> while in
              the email subject the position you are interested in applying for.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Careers;
