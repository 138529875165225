import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  BrowserRouter,
  HashRouter,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import About from "./screens/about/About";
import ArticleDetails from "./screens/article details";
import CareerDetails from "./screens/career details";
import Careers from "./screens/careers";
import Contactus from "./screens/contact-us/Contactus";
import Footer from "./components/footer/Footer";
import Home from "./screens/homePage/Home";
import Law from "./screens/sub-services/Law";
import NavBar from "./components/navbar/NavBar";
import Profile from "./screens/client-profile/Profile";
import Services from "./screens/servicesPage/Services";
import Team from "./screens/teamPage/Team";

const App = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Jurisera</title>
        <meta
          name="description"
          content="Jurisera is a full-service business law firm advising and supporting local, regional, and international corporations, financial institutions, and high-profile individual clients in Egypt, Africa and the MENA region."
        />
        ;
      </Helmet>
      <Router>
        <BrowserRouter basename="/jurisera">
          <NavBar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/practices" component={Services} />
            <Route exact path="/our-team" component={Team} />
            <Route exact path="/our-team/profile/:id" component={Profile} />
            <Route exact path="/law/:id" component={Law} />
            <Route exact path="/contact-us" component={Contactus} />
            <Route exact path="/about-us" component={About} />

            <Route exact path="/about-us" component={About} />
            <Route exact path="/careers" component={Careers} />
            <Route exact path="/careers/:id" component={CareerDetails} />

            <Route exact path="/article/:id" component={ArticleDetails} />
          </Switch>
          <Footer />
        </BrowserRouter>
      </Router>
    </HelmetProvider>
  );
};

export default App;
