import "./Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useEffect, useState } from "react";

import BackLogo from "./../../assets/images/Union_logo.svg";
import BkImage from "./../../assets/Group106.png";
import Carousel from "react-elastic-carousel";
import HomeSlider from "../../components/Home-slider2/HomeSlider";
import ImageGallery from "../../components/image-gallery/ImageGallery";
import Items from "../../components/items/Items";
import PracticesSlider from "./../../components/practices-slider/PracticesSlider";
import ServicesComp from "../../components/servicesComp/ServicesComp";
import Slider from "react-slick";
import Test from "./../../assets/images/testt.png";
import TestImage from "./../../assets/images/finalCairo.jpg";
import WOW from "wowjs";
import axios from "axios";

const Home = (props) => {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  const breakPoints = [
    { width: 1, itemsToShow: 1, pagination: false },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 768, itemsToShow: 3, pagination: false },
    { width: 1200, itemsToShow: 4, pagination: false },
  ];
  const breakPoints2 = [
    { width: 1, itemsToShow: 1.2, pagination: false },
    { width: 550, itemsToShow: 1.5, itemsToScroll: 2, pagination: false },
    { width: 768, itemsToShow: 1.45, pagination: false },
    { width: 1200, itemsToShow: 1.45, pagination: false },
  ];
  const breakPoints3 = [
    { width: 1, itemsToShow: 1, pagination: false },
    { width: 400, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 550, itemsToShow: 3, pagination: false },
    { width: 768, itemsToShow: 4, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 4, pagination: false },
  ];
  const [services, setServices] = useState([]);
  const [qoutes, setQoutes] = useState([]);
  const [news, setNews] = useState([]);

  const [selectedPartner, setSelectedPartner] = useState({});

  const GetServices = () => {
    axios
      .get("https://backend.jurisera.com/api/services/?ordering=id")
      .then((res) => {
        setServices(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const GetQoutes = () => {
    axios
      .get("https://backend.jurisera.com/api/quotes/?ordering=id")
      .then((res) => {
        setQoutes(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const GetNews = () => {
    axios
      .get("https://backend.jurisera.com/api/posts/?ordering=id")
      .then((res) => {
        setNews(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const [isMobile, setIsMobile] = useState("");
  useEffect(() => {
    const test = window.innerWidth < 1024;
    setIsMobile(test);
    console.log("ismobile", test);
    GetServices();
    GetQoutes();
    GetNews();
    if (document.getElementById("article-up")) {
      document
        .getElementById("article-up")
        .scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 785,
        settings: {
          dots: false,
          infinite: true,
          speed: 600,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };

  return (
    <div
      className="homePage-Contatiner"
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `url(${BkImage})`,
        height: "auto",
        width: "100%",
        backgroundSize: "100% 111%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <img
        src={TestImage}
        className={isMobile ? "cairo_test_MV" : "cairo_test"}
      />

      <div>
        {/* <div className="our-practices pt-5">Our Practices</div>
        <div className="break">
          {services.map((item) => (
            <ServicesComp item={item} />
          ))}
        </div> */}
        <div className="row m-0 pt-5">
          <div className="col-2 our-practice">Practices</div>
          <div className="classR col-lg-9 col-sm-12" id="dodo3">
            <Carousel breakPoints={breakPoints3}>
              {services.map((item) => (
                <PracticesSlider item={item} />
              ))}
            </Carousel>
          </div>
        </div>
        {/* <div className="our-team-bk m-0 row mt-5">
          <div className="our-team-members col-lg-4 col-sm-12 d-flex flex-column justify-content-between">
            <div>Meet our team</div>
            <div className="">
              <p className="selected-name">{selectedPartner.name}</p>
              <p className="selected-description">
                {selectedPartner.description}
              </p>
            </div>
          </div>
          <div className="col-lg-8 col-sm-12 d-flex gallery-content">
            <div className="row gallery-container" style={{ width: "100%" }}>
              <ImageGallery setData={setSelectedPartner} />
            </div>
          </div>
        </div> */}
        <div className="our-team-bk m-0 row mt-5">
          <div className="our-team-members col-lg-4 col-sm-12 d-flex flex-column justify-content-between">
            <div style={{ color: "#936957" }}>Our Team</div>
            <div className="">
              <p className="selected-name">{selectedPartner.name}</p>
              <p className="selected-description">
                {selectedPartner.description}
              </p>
            </div>
          </div>
          <div className="col-lg-8 col-sm-12 d-flex gallery-content">
            <div className="row gallery-container" style={{ width: "100%" }}>
              <ImageGallery setData={setSelectedPartner} />
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "#fff",
            paddingTop: 20,
            paddingBottom: 25,
          }}
        >
          <div
            className="justify-content-center mb-5 "
            style={{
              backgroundPosition: "center",
              backgroundImage: `url(${BackLogo})`,
              height: 223,
              width: "100%",
              backgroundSize: "100% 91%",
              backgroundRepeat: "no-repeat",
              paddingTop: 5,
            }}
          >
            <div className="carousel-wrapper mb-5 mt-5" id="dodo2">
              <Slider {...settings}>
                {qoutes.map((item) => (
                  <HomeSlider item={item} />
                ))}
              </Slider>
            </div>
          </div>
        </div>

        <div id="News" className="container our-news-container">
          <div className="our-news">News</div>
          <div className="carousel-wrapper" id="dodo">
            <Carousel breakPoints={breakPoints}>
              {news.map((item) => (
                <div
                  onClick={() =>
                    props.history.push({
                      pathname: `/article/${item.id}`,
                      state: { selected_id: "article-up" },
                    })
                  }
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Items item={item} />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
